<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :footer="null"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="搜索" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-search
              enter-button
              placeholder="身份证姓名 / 身份证号码 / 手机号码"
              v-decorator="['keyword', {rules: [{required: true, message: '请输入身份证姓名 / 身份证号码 / 手机号码'}]}]"
              @search="onSearch"/>
        </a-form-item>

        <a-form-item v-if="!isEmpty(record)" label=" " :colon="false" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div style="padding: 10px;border: 1px solid #e8e8e8;line-height: normal">
            <div style="font-weight: 500;margin-bottom: 10px">搜索结果，核对人员信息，确认无误点击请确认录用：</div>
            <div style="line-height: 20px">
              <div>身份证姓名：<a>{{ record.personnel_name }}</a></div>
              <div>身份证号码：<a>{{ record.idcard_number }}</a></div>
              <div>手机号码：<a>{{ record.mobile }}</a></div>
              <div>职位：
                <a-tag color="blue" v-if="record.personnel_type==0">司机</a-tag>
                <a-tag color="orange" v-else>押运员</a-tag>
              </div>
            </div>
          </div>

          <a-button type="primary" style="margin-top: 20px" @click="handleSubmit">确定录用</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/personnel'
import {isEmpty} from "@/utils/util";

export default {
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: ""
    }
  },
  methods: {
    isEmpty,

    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '入职员工'

      this.visible = true
    },

    onSearch(e) {
      Api.getInfo({keyword: e}).then(({data: {info}}) => {
        if (!info) {
          this.$message.error('未找到相关人员信息')
          this.record = {}
          return
        }
        this.record = info
      })
    },

    handleSearch() {
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      if (isEmpty(this.record)) {
        this.$message.error('人员信息不存在')
        return false
      }

      Api.entry({personnelId: this.record['personnel_id']}).then((result) => {
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit')
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({costId: this.record['cost_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
