<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-col :span="6">
          <a-form-item label="身份证正面" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <UploadImage
                btnText="上传正面"
                :file="record['idcard_front']"
                v-decorator="['idcard_front_id', {rules: [{required: true, message: '请上传身份证正面'}]}]"
                @change="handleIdcardIdentify"
            />
            <div class="form-item-help">
              <p class="extra">身份证头像面拍照上传，上传后可自动将下列信息补全</p>
            </div>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="身份证反面" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <UploadImage
                btnText="上传反面"
                :file="record['idcard_opposite']"
                v-decorator="['idcard_opposite_id', {rules: [{required: true, message: '请上传身份证反面'}]}]"
            />
            <div class="form-item-help">
              <p class="extra">身份证国徽面拍照上传</p>
            </div>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="从业资格证" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <UploadImage
                btnText="点击上传"
                :file="record['cyzgz']"
                v-decorator="['cyzgz_id', {rules: [{required: true, message: '请上传从业资格证拍照'}]}]"
                @change="handleCyzgzIdentify"
            />
            <div class="form-item-help">
              <p class="extra">从业资格证拍照上传，上传后可自动将下列信息补全</p>
            </div>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="驾驶证" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <UploadImage
                btnText="点击上传"
                :file="record['jsz_front']"
                v-decorator="['jsz_front_id']"
                @change="handleJszIdentify"
            />
            <div class="form-item-help">
              <p class="extra">驾驶证拍照上传，上传后可自动将下列信息补全</p>
            </div>
          </a-form-item>
        </a-col>

        <a-divider orientation="left">基本信息</a-divider>
        <a-row>
          <a-col :span="12">
            <a-form-item label="职位" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group
                  button-style="solid"
                  v-decorator="['personnel_type', { initialValue: 0,rules: [{ required: true }] }]"
              >
                <a-radio-button :value="0">司机</a-radio-button>
                <a-radio-button :value="1">押运员</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="手机号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input
                  placeholder="请输入手机号码"
                  v-decorator="['mobile', {rules: [{required: true, message: '请输入手机号码'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="人员状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group
                  v-decorator="['status', { initialValue: 0,rules: [{ required: true }] }]"
              >
                <a-radio :value="0">在职</a-radio>
                <a-radio :value="1">离职</a-radio>
                <a-radio :value="2">请假中</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider orientation="left">身份证信息</a-divider>
        <a-col :span="12">
          <a-form-item label="身份证姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入身份证姓名"
                v-decorator="['personnel_name', {rules: [{required: true, message: '请输入身份证姓名'}]}]"
            />
          </a-form-item>
          <a-form-item label="身份证号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入身份证号码"
                v-decorator="['idcard_number', {rules: [{required: true, message: '请输入身份证号码'}]}]"
            />
          </a-form-item>
          <a-form-item label="住址" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入住址"
                v-decorator="['idcard_zz', {rules: [{required: true, message: '请输入住址'}]}]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="出生日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入出生日期"
                v-decorator="['birthdate', {rules: [{required: true, message: '请输入出生日期'}]}]"
            />
          </a-form-item>
          <a-form-item label="身份证性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-radio-group
                v-decorator="['gender', { initialValue: 0,rules: [{ required: true }] }]"
            >
              <a-radio :value="0">男</a-radio>
              <a-radio :value="1">女</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-divider orientation="left">从业资格证信息</a-divider>
        <a-col :span="12">
          <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入姓名"
                v-decorator="['cyzgz_xm', {rules: [{required: true, message: '请输入姓名'}]}]"
            />
          </a-form-item>
          <a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入性别"
                v-decorator="['cyzgz_xb', {rules: [{required: true, message: '请输入性别'}]}]"
            />
          </a-form-item>
          <a-form-item label="证号" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入证号"
                v-decorator="['cyzgz_zh', {rules: [{required: true, message: '请输入证号'}]}]"
            />
          </a-form-item>
          <a-form-item label="有效期限" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入有效期限"
                v-decorator="['cyzgz_yxqx', {rules: [{required: true, message: '请输入有效期限'}]}]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">

          <a-form-item label="核发机关" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入核发机关"
                v-decorator="['cyzgz_hfjg', {rules: [{required: true, message: '请输入核发机关'}]}]"
            />
          </a-form-item>
          <a-form-item label="从业资格类别" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入从业资格类别"
                v-decorator="['cyzgz_lb', {rules: [{required: true, message: '请输入从业资格类别'}]}]"
            />
          </a-form-item>
          <a-form-item label="有效起始日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入有效起始日期"
                v-decorator="['cyzgz_yxqsrq', {rules: [{required: true, message: '请输入有效起始日期'}]}]"
            />
          </a-form-item>
        </a-col>

        <a-divider orientation="left">驾驶证信息</a-divider>
        <a-col :span="12">
          <a-form-item label="驾驶证姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入驾驶证姓名"
                v-decorator="['jsz_name']"
            />
          </a-form-item>
          <a-form-item label="驾驶证号" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入驾驶证号"
                v-decorator="['jsz_number']"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="准驾车型" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入准驾车型"
                v-decorator="['jsz_zjcx']"
            />
          </a-form-item>
          <a-form-item label="有效期限" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                placeholder="请输入有效期限"
                v-decorator="['jsz_yxqx']"
            />
          </a-form-item>
        </a-col>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/personnel'
import {UploadFile, UploadImage} from '@/components'
import _ from "lodash";
import * as identifyApi from "@/api/identify";
import {timeNumberToString} from "@/utils/util";

export default {
  components: {
    UploadFile,
    UploadImage
  },
  data() {
    return {
      // 标签页索引
      tabKey: 0,
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 10
      },
      // 输入框布局属性
      wrapperCol: {
        span: 14
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: ""
    }
  },
  methods: {
    handleIdcardIdentify(item, file) {
      const postData = {
        url: file[0].file_path
      }
      this.confirmLoading = true
      identifyApi.getIdcardInfo(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'personnel_name': data['姓名'].words,
          'idcard_number': data['公民身份号码'].words,
          'birthdate': timeNumberToString(data['出生'].words),
          'gender': data['性别'].words == '男' ? 0 : 1,
          'idcard_zz': data['住址'].words,
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    handleCyzgzIdentify(item, file) {
      const postData = {
        url: file[0].external_url
      }
      this.confirmLoading = true
      identifyApi.getGeneralBasic(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'cyzgz_xm': data[4].words,
          'cyzgz_xb': data[6].words,
          'cyzgz_zh': data[15].words,
          'cyzgz_lb': data[18].words,
          'cyzgz_yxqsrq': data[20].words,
          'cyzgz_yxqx': data[22].words,
          'cyzgz_hfjg': data[25].words,
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    handleJszIdentify(item, file) {
      const postData = {
        url: file[0].file_path
      }
      this.confirmLoading = true
      identifyApi.getDrivingLicenseInfo(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'jsz_name': data['姓名'].words,
          'jsz_number': data['证号'].words,
          'jsz_zjcx': data['准驾车型'].words,
          'jsz_yxqx': timeNumberToString(data['有效起始日期'].words) + ' 至 ' + data['有效期限'].words,
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },


    /**
     * 显示对话框
     */
    add() {
      this.tabKey = 0
      this.mode = "add"
      this.title = '新增驾押信息'
      this.visible = true
    },
    edit(record) {
      this.tabKey = 0
      this.mode = "edit"
      this.title = '编辑驾押信息'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    handleTabs(key) {
      this.tabKey = key
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, Object.keys(this.record))
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        if (errors) {
          this.onTargetTabError(errors)
          return false
        }
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 定位到错误的tab选项卡
     */
    onTargetTabError(errors) {
      // 表单字段与tabKey对应关系
      // 只需要必填字段就可
      const tabsFieldsMap = [
        ['personnel_type', 'mobile', 'status'],
        ['idcard_front_id', 'idcard_opposite_id', 'personnel_name', 'gender', 'birthdate', 'idcard_number'],
        [],
        ['cyzgz_id', 'cyzgz_lb', 'cyzgz_yxqsrq', 'cyzgz_yxqx', 'cyzgz_hfjg']
      ]
      const field = Object.keys(errors).shift()
      for (const key in tabsFieldsMap) {
        if (tabsFieldsMap[key].indexOf(field) > -1) {
          this.tabKey = parseInt(key)
          break
        }
      }
    },


    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({personnelId: this.record['personnel_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
